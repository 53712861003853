
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Pageniation as Table } from "@/utils/decorator";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";

const staff = namespace("staff");
const user = namespace("user");
const setting = namespace("setting");
@Component({
  components: { TableEnableFlag }
})
@Table("loadListData")
export default class Staff extends Vue {
  @staff.Action queryOpAccountList;
  @user.State userData;
  @setting.Action getRoleList;
  data = {};
  roleList: any = [];
  searchForm = {
    userName: "",
    mobile: ""
  };
  staffList: any[] = [
    {
      roleList: []
    }
  ];
  get searchList() {
    return [
      {
        label: this.$t("v210801.full-name"),
        type: "input",
        value: "",
        prop: "userName"
      },
      {
        label: "staff.staff-phone",
        type: "input",
        value: "",
        prop: "mobile"
      },
      {
        label: this.$t("base.role"),
        type: "select",
        value: "",
        prop: "roleCode",
        selectList: this.roleList
      }
    ];
  }
  tableLoading: boolean = false;
  created() {
    this.loadListData();
    this.getALlRoleList();
  }
  getALlRoleList() {
    this.getRoleList({
      pageNum: 1,
      pageSize: 999
    }).then(data => {
      this.roleList = data.data.list.map(item => {
        return {
          label: item.roleName,
          value: item.roleCode
        };
      });
    });
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.queryOpAccountList({
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize,
      ...this.searchForm
    }).then(data => {
      this.staffList = data.data.list;
      return data;
    });
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
  handleEdit(staff) {
    this.$router.push(`/setting/user-edit/${staff.userCode}`);
  }
}
